
import PubSub from "@/lib/Pub";
export default {
	name: "server2",
	data: () => {
		return {
			activeData: {
				title: "管理服务",
				data: [
					{
						img: require("@/assets/server2-1.png"),
						title: "管理目的",
						text:
							"搭建临床试验机构、SMO和申办方等多方共享的信息平台；形成 CRC 工作量客观评价指标及提升机构对CRC工作的支持和管理能力"
					},
					{
						img: require("@/assets/server2-2.png"),
						title: "管理申请",
						text:
							"由 SMO 公司、药物临床试验机构向 C&C Club 平台申请管理 CRC 资格"
					},
					{
						img: require("@/assets/server2-3.png"),
						title: "管理准则",
						text: "遵守赫尔辛基宣言和 GCP 原则，客观、科学、公正原则"
					},
					{
						img: require("@/assets/server2-4.png"),
						title: "管理人员",
						text: "由 SMO 公司及机构相关人员对 CRC 进行人性化及智能化管理"
					},
					{
						img: require("@/assets/server2-5.png"),
						title: "管理内容",
						text:
							"包括 CRC 的人员维护,项目维护,监测 CRC 人员分布、机构位置分布及项目启 动等各类数据报表等"
					},
					{
						img: require("@/assets/server2-6.png"),
						title: "管理方式",
						text:
							"提供一套辅助 CRC 日常工作的互联网工具,作为各方管理 CRC 的助手"
					}
				]
			}
		};
	},
	methods: {
		openCrc() {
			if (!this.$root.userInfo.name) {
				PubSub.publish("showLoginEvent");
			} else {
				if (this.$root.userInfo.type == 1) {
					let h0 = window.location.protocol + "//" + window.location.host
					window.location.href = h0 + "/#/statistics";
				} else if (this.$root.userInfo.type == 2) {
					let h0 = window.location.protocol + "//" + window.location.host
					window.location.href = h0 + "/#/smoStatistics";
				} else {
					this.$message({
						showClose: true,
						message: "对不起，您无访问权限！",
						type: "warning"
					});
				}
			}
		}
	}
};
